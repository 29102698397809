import { motion, animate } from 'framer-motion';
import { useState, useEffect, useRef } from 'react';

interface Statistic {
  type: 'title' | 'number';
  text?: string;
  value?: number;
}

const stats: Statistic[] = [
  { 
    type: 'title'
  },
  { type: 'number', text: 'games created', value: 42 },
  { type: 'number', text: 'improves made', value: 156 },
  { type: 'number', text: 'games played', value: 1234 },
  { type: 'number', text: 'active users', value: 89 }
];

const Counter = ({ value, isVisible }: { value: number, isVisible: boolean }) => {
  const nodeRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (!nodeRef.current || !isVisible) return;
    
    const node = nodeRef.current;
    const controls = animate(0, value, {
      duration: 2,
      onUpdate(value) {
        node.textContent = Math.round(value).toString();
      },
      ease: "easeOut"
    });

    return () => controls.stop();
  }, [value, isVisible]);

  return (
    <span ref={nodeRef}>0</span>
  );
};

const HomePage = () => {
  const [currentStatIndex, setCurrentStatIndex] = useState(0);
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const startTimer = () => {
      timerRef.current = setInterval(() => {
        setCurrentStatIndex((prev) => (prev + 1) % stats.length);
      }, 3000);
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Clear the timer when page is hidden
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
      } else {
        // Reset to first slide and restart timer when page becomes visible
        setCurrentStatIndex(0);
        startTimer();
      }
    };

    // Add visibility change listener
    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    // Start initial timer
    startTimer();

    // Cleanup
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-black text-white flex flex-col items-center justify-center relative overflow-hidden">
      {/* Background gradient */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-radial from-emerald-500/20 via-transparent to-transparent scale-100 md:scale-150" />
        <div className="absolute inset-0 bg-gradient-radial from-emerald-500/10 via-transparent to-transparent scale-150 md:scale-200 translate-y-1/2" />
      </div>
      
      {/* Statistics and Title */}
      <div className="relative min-h-[16rem] md:h-48 z-10 w-full max-w-7xl mx-auto px-4 flex items-center justify-center">
        {stats.map((stat, index) => (
          <motion.div
            key={index}
            className="absolute w-full max-w-[90%] md:max-w-full"
            initial={{ opacity: 0, y: 20 }}
            animate={{ 
              opacity: currentStatIndex === index ? 1 : 0,
              y: currentStatIndex === index ? 0 : 20
            }}
            transition={{ duration: 0.5 }}
          >
            {stat.type === 'title' ? (
              <div className="text-center flex flex-col items-center">
                <div className="text-4xl sm:text-5xl md:text-7xl font-extrabold break-words max-w-5xl">we are about to</div>
                <div className="text-4xl sm:text-5xl md:text-7xl font-extrabold break-words max-w-5xl">
                  <span className="text-red-500">kill</span> gaming studios
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center space-y-2">
                <div className="text-5xl sm:text-6xl md:text-8xl font-bold text-center">
                  <Counter 
                    value={stat.value!} 
                    isVisible={currentStatIndex === index}
                  />
                </div>
                <div className="text-3xl sm:text-4xl md:text-6xl font-bold text-center break-words">
                  {stat.text}
                </div>
              </div>
            )}
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default HomePage; 