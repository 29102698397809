import { motion, AnimatePresence } from 'framer-motion';
import { useState, useRef, useEffect } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import '../styles/fullpage-override.css';

interface SlideData {
  title: string;
  subtitle: string;
}

const slides: SlideData[] = [
  { 
    title: "AI Finetuning", 
    subtitle: "Smart feels like an understatement, we're starting a revolution." 
  },
  { 
    title: "Game Creation Platform", 
    subtitle: "Building the stage for gamers to create: just pure creativity." 
  },
  { 
    title: "Token Launch", 
    subtitle: "True ownership starts here." 
  },
  { 
    title: "Community Takeover", 
    subtitle: "Airdrops and contests that actually matter. Your contribution = Your reward. Real rewards, zero BS." 
  },
  { 
    title: "ADS Protocol", 
    subtitle: "Every 24h one bid takes the spotlight. The others fade to zero." 
  },
  { 
    title: "Listing", 
    subtitle: "Going public, going loud, no apologies." 
  },
  { 
    title: "In-app purchases", 
    subtitle: "Creators earn big, holders earn always" 
  },
  { 
    title: "Next-Gen Gaming", 
    subtitle: "Making games great again. Complex games built by the community, for the community." 
  },
];

interface Particle {
  x: number;
  y: number;
  vx: number;
  vy: number;
  size: number;
  targetX: number;
  targetY: number;
}

const ParticleField = ({ progress, direction }: { progress: number, direction: 'up' | 'down' }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const particlesRef = useRef<Particle[]>([]);
  const animationFrameRef = useRef<number>();
  const isTransitioningRef = useRef(false);
  const lastUpdateRef = useRef(Date.now());

  const startTransition = () => {
    isTransitioningRef.current = true;
    lastUpdateRef.current = Date.now();
    
    setTimeout(() => {
      isTransitioningRef.current = false;
    }, 1200);
  };

  useEffect(() => {
    startTransition();
  }, [direction, progress]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const setSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    setSize();
    window.addEventListener('resize', setSize);

    if (particlesRef.current.length === 0) {
      const numParticles = 40;
      for (let i = 0; i < numParticles; i++) {
        particlesRef.current.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          vx: 0,
          vy: 0,
          size: Math.random() * 1.5 + 0.5,
          targetX: Math.random() * canvas.width,
          targetY: Math.random() * canvas.height,
        });
      }
    }

    const animate = () => {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.2)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      const isInTransition = isTransitioningRef.current;
      
      particlesRef.current.forEach((particle, i) => {
        const dx = particle.targetX - particle.x;
        const dy = particle.targetY - particle.y;

        const force = direction === 'down' ? 1 : -1;
        
        const distanceMultiplier = isInTransition ? 250 : 50;
        const transitionForce = isInTransition ? 0.03 : 0.008;
        const maxSpeed = isInTransition ? 5 : 2;
        const friction = isInTransition ? 0.92 : 0.98;
        
        if (Math.random() < (isInTransition ? 0.1 : 0.02)) {
          particle.targetX = particle.x + (Math.random() - 0.5) * distanceMultiplier;
          particle.targetY = particle.y + (Math.random() - 0.5) * distanceMultiplier * force;
        }
        
        particle.vx += dx * transitionForce;
        particle.vy += dy * transitionForce;
        
        particle.vx = Math.max(Math.min(particle.vx, maxSpeed), -maxSpeed);
        particle.vy = Math.max(Math.min(particle.vy, maxSpeed), -maxSpeed);

        particle.vx *= friction;
        particle.vy *= friction;

        const perlinScale = isInTransition ? 0.2 : 0.1;
        particle.x += Math.sin(Date.now() * 0.001 + i) * perlinScale;
        particle.y += Math.cos(Date.now() * 0.001 + i) * perlinScale;

        particle.x += particle.vx;
        particle.y += particle.vy;

        if (particle.x < -50) particle.x = canvas.width + 50;
        if (particle.x > canvas.width + 50) particle.x = -50;
        if (particle.y < -50) particle.y = canvas.height + 50;
        if (particle.y > canvas.height + 50) particle.y = -50;

        ctx.shadowBlur = isInTransition ? 20 : 15;
        ctx.shadowColor = i % 5 === 0 ? '#64ffda' : '#ffffff';
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.size * (isInTransition ? 1.2 : 1), 0, Math.PI * 2);
        ctx.fillStyle = i % 5 === 0 ? '#64ffda' : '#ffffff';
        ctx.fill();
        ctx.shadowBlur = 0;
      });

      animationFrameRef.current = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      window.removeEventListener('resize', setSize);
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [direction]);

  return <canvas ref={canvasRef} className="fixed inset-0 z-0" style={{ pointerEvents: 'none' }} />;
};

const PathPage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [direction, setDirection] = useState<'up' | 'down'>('down');

  const Slide = ({ data }: { data: SlideData }) => {
    const scaleValues = {
      initial: direction === 'down' ? 0.7 : 1.4,
      animate: 1
    };

    return (
      <motion.div
        className="text-center absolute w-full"
        initial={{ scale: scaleValues.initial, opacity: 0 }}
        animate={{ scale: scaleValues.animate, opacity: 1 }}
        exit={{ 
          opacity: 0,
          transition: { 
            duration: 1.2,
            ease: "easeInOut"
          }
        }}
        transition={{ 
          duration: 1.2,
          ease: "easeInOut",
          delay: 0.3
        }}
      >
        <div className="relative h-36 md:h-40 flex flex-col items-center">
          <motion.h2
            className="text-3xl md:text-5xl font-black absolute top-2"
            initial={{ opacity: 0, y: 60 }}
            animate={{ 
              opacity: 1,
              y: 0,
              transition: {
                opacity: { duration: 0.1 },
                y: {
                  duration: 0.6,
                  delay: 1.5,
                  ease: "easeOut"
                }
              }
            }}
            exit={{ 
              opacity: 0,
              transition: { duration: 1.2 }
            }}
          >
            {data.title}
          </motion.h2>
          
          <motion.p
            className="text-xl md:text-2xl text-white/90 max-w-3xl mx-auto absolute bottom-2 px-4"
            initial={{ opacity: 0 }}
            animate={{ 
              opacity: 1,
              transition: {
                duration: 0.5,
                delay: 2.2
              }
            }}
            exit={{ 
              opacity: 0,
              transition: { duration: 1.2 }
            }}
          >
            {data.subtitle}
          </motion.p>
        </div>
      </motion.div>
    );
  };

  return (
    <div className="fixed inset-0 bg-black text-white">
      <div className="fixed inset-0 -z-10 bg-black" />
      <ParticleField 
        progress={currentSlide / (slides.length - 1)} 
        direction={direction} 
      />
      
      <div className="fixed inset-0 flex items-center justify-center z-20 pointer-events-none">
        <div className="relative w-full pb-32">
          <AnimatePresence mode="wait">
            <Slide
              key={currentSlide}
              data={slides[currentSlide]}
            />
          </AnimatePresence>
        </div>
      </div>
      
      <ReactFullpage
        credits={{ enabled: false }}
        scrollingSpeed={800}
        onLeave={(_origin, destination, dir) => {
          setDirection(dir === 'down' ? 'down' : 'up');
          setCurrentSlide(destination.index);
        }}
        render={({ state, fullpageApi }) => (
          <ReactFullpage.Wrapper>
            {slides.map((_, index) => (
              <div key={index} className="section !bg-transparent" />
            ))}
          </ReactFullpage.Wrapper>
        )}
      />
    </div>
  );
};

export default PathPage;
