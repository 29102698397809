import { motion } from 'framer-motion';

const EcosystemPage = () => {
  return (
    <div className="min-h-screen bg-black text-white pt-40">
      <div className="max-w-7xl mx-auto px-4">
        {/* Hero Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="mb-40"
        >
          <h1 className="text-5xl sm:text-6xl md:text-[7rem] font-black mb-8">ecosystem</h1>
          <p className="text-3xl md:text-4xl text-white">
            we are going to <span className="text-red-500 font-bold">disrupt</span> the $300B gaming market
          </p>
        </motion.div>

        {/* Problem Statement */}
        <section className="mb-40">
          <div>
            <h2 className="text-4xl sm:text-4xl md:text-[4rem] font-black mb-8">
              dead market
            </h2>
            <div className="space-y-8 text-3xl md:text-4xl text-white">
              <p>creativity killed by profit</p>
              <p>only remakes and microtransactions</p>
              <p>technical barriers blocked innovation</p>
              <p className="text-emerald-400 font-bold">AI changes everything.</p>
            </div>
          </div>
        </section>

        {/* The Solution */}
        <section className="mb-40">
          <div>
            <h2 className="text-4xl sm:text-4xl md:text-[4rem] font-black mb-8">
              the fix
            </h2>
            <div className="space-y-8 text-3xl md:text-4xl text-white">
              <p>a platform powered by AI</p>
              <p>where anyone can create their games</p>
              <p className="text-emerald-400 font-bold">and the community owns everything.</p>
            </div>
          </div>
        </section>

        {/* Core Vision */}
        <section className="mb-40">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-32">
            <div>
              <div className="mb-16">
                <div className="flex items-center gap-4 md:block mb-4">
                  <p className="text-5xl sm:text-6xl md:text-[8rem] font-black text-white/40">01</p>
                  <h3 className="text-3xl sm:text-4xl md:text-[4rem] font-black md:mt-4">speak</h3>
                </div>
              </div>
              <div className="space-y-8 text-3xl md:text-4xl text-white">
                <p>describe your game idea</p>
                <p>our AI understands and builds</p>
                <p>playable in seconds</p>
                <p className="text-emerald-400 font-bold">mobile-first. viral-ready.</p>
              </div>
            </div>

            <div>
              <div className="mb-16">
                <div className="flex items-center gap-4 md:block mb-4">
                  <p className="text-5xl sm:text-6xl md:text-[8rem] font-black text-white/40">02</p>
                  <h3 className="text-3xl sm:text-4xl md:text-[4rem] font-black md:mt-4">share</h3>
                </div>
              </div>
              <div className="space-y-8 text-3xl md:text-4xl text-white">
                <p>one link to play</p>
                <p>no app stores</p>
                <p>no downloads</p>
                <p className="text-emerald-400 font-bold">instant global distribution.</p>
              </div>
            </div>

            <div>
              <div className="mb-16">
                <div className="flex items-center gap-4 md:block mb-4">
                  <p className="text-5xl sm:text-6xl md:text-[8rem] font-black text-white/40">03</p>
                  <h3 className="text-3xl sm:text-4xl md:text-[4rem] font-black md:mt-4">evolve</h3>
                </div>
              </div>
              <div className="space-y-8 text-3xl md:text-4xl text-white">
                <p>anyone can improve games</p>
                <p>ideas become feautres</p>
                <p>concepts turn into worlds</p>
                <p className="text-emerald-400 font-bold">community-driven creativity.</p>
              </div>
            </div>

            <div>
              <div className="mb-16">
                <div className="flex items-center gap-4 md:block mb-4">
                  <p className="text-5xl sm:text-6xl md:text-[8rem] font-black text-white/40">04</p>
                  <h3 className="text-3xl sm:text-4xl md:text-[4rem] font-black md:mt-4">earn</h3>
                </div>
              </div>
              <div className="space-y-8 text-3xl md:text-4xl text-white">
                <p>create games that players love</p>
                <p>improve existing games</p>
                <p>share platform success</p>
                <p className="text-emerald-400 font-bold">fair revenue sharing.</p>
              </div>
            </div>
          </div>
        </section>

        {/* App Store Parallel */}
        <section className="mb-40">
          <div>
            <h2 className="text-4xl sm:text-4xl md:text-[4rem] font-black mb-8">history repeats itself</h2>
            <div className="space-y-8 text-3xl md:text-4xl text-white">
              <p>App Store showed what happens</p>
              <p>when you give creators easy tools and distribution</p>
              <p className="text-red-500 font-bold">but they take 30% of all revenue</p>
              <p>we give 70% to creators, 30% to holders</p>
            </div>
          </div>
        </section>

        {/* Market Evolution */}
        <section className="mb-40">
          <div>
            <h2 className="text-4xl sm:text-4xl md:text-[4rem] font-black mb-8">another market shift</h2>
            <div className="space-y-8 text-3xl md:text-4xl text-white">
              <p>marketing was controlled by agencies</p>
              <p>until creators took over</p>
              <p>gaming will follow the same path</p>
              <p>from few studios to many creators</p>
              <p className="text-emerald-400 font-bold">AI will democratize game creation</p>
            </div>
          </div>
        </section>

        {/* Token Power */}
        <section className="mb-40">
          <div>
            <h2 className="text-4xl sm:text-4xl md:text-[4rem] font-black mb-8">the fuel</h2>
            <div className="space-y-8 text-3xl md:text-4xl text-white">
              <p>$DEGS aligns all incentives</p>
              <p>creators earn for building</p>
              <p>holders earn from platform growth</p>
              <p>players access unique experiences</p>
              <p className="text-emerald-400 font-bold">full ecosystem alignment.</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default EcosystemPage; 