import { motion } from 'framer-motion';

const BuyPage = () => {
  return (
    <div className="min-h-screen bg-black text-white pt-40">
      <div className="max-w-7xl mx-auto px-4">
        {/* Hero Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="mb-40"
        >
          <h1 className="text-5xl sm:text-6xl md:text-[7rem] font-black mb-8">buy</h1>
          <p className="text-3xl md:text-4xl text-white">
            own the <span className="text-emerald-400 font-bold">future</span> of gaming
          </p>
        </motion.div>

        {/* Coming Soon */}
        <section className="mb-40">
          <div>
            <h2 className="text-4xl sm:text-4xl md:text-[4rem] font-black mb-8">
              launching soon
            </h2>
            <div className="space-y-8 text-3xl md:text-4xl text-white">
              <p>real-time price data</p>
              <p>direct DEX integration</p>
              <p>instant swaps</p>
              <p className="text-emerald-400 font-bold">fair launch. no presale.</p>
            </div>
          </div>
        </section>

        {/* Distribution */}
        <section className="mb-40">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-32">
            <div>
              <div className="mb-16">
                <div className="flex items-center gap-4 md:block mb-4">
                  <p className="text-5xl sm:text-6xl md:text-[8rem] font-black text-white/40">01</p>
                  <h3 className="text-3xl sm:text-4xl md:text-[4rem] font-black md:mt-4">liquidity</h3>
                </div>
              </div>
              <div className="space-y-8 text-3xl md:text-4xl text-white">
                <p>55% of total supply</p>
                <p>locked for 24 months</p>
                <p className="text-emerald-400 font-bold">maximum security.</p>
              </div>
            </div>

            <div>
              <div className="mb-16">
                <div className="flex items-center gap-4 md:block mb-4">
                  <p className="text-5xl sm:text-6xl md:text-[8rem] font-black text-white/40">02</p>
                  <h3 className="text-3xl sm:text-4xl md:text-[4rem] font-black md:mt-4">community</h3>
                </div>
              </div>
              <div className="space-y-8 text-3xl md:text-4xl text-white">
                <p>15% of total supply</p>
                <p>for early supporters</p>
                <p className="text-emerald-400 font-bold">power to believers.</p>
              </div>
            </div>

            <div>
              <div className="mb-16">
                <div className="flex items-center gap-4 md:block mb-4">
                  <p className="text-5xl sm:text-6xl md:text-[8rem] font-black text-white/40">03</p>
                  <h3 className="text-3xl sm:text-4xl md:text-[4rem] font-black md:mt-4">team</h3>
                </div>
              </div>
              <div className="space-y-8 text-3xl md:text-4xl text-white">
                <p>10% of total supply</p>
                <p>2-year linear vesting</p>
                <p className="text-emerald-400 font-bold">aligned incentives.</p>
              </div>
            </div>

            <div>
              <div className="mb-16">
                <div className="flex items-center gap-4 md:block mb-4">
                  <p className="text-5xl sm:text-6xl md:text-[8rem] font-black text-white/40">04</p>
                  <h3 className="text-3xl sm:text-4xl md:text-[4rem] font-black md:mt-4">marketing</h3>
                </div>
              </div>
              <div className="space-y-8 text-3xl md:text-4xl text-white">
                <p>20% of total supply</p>
                <p>12-month release</p>
                <p className="text-emerald-400 font-bold">sustainable growth.</p>
              </div>
            </div>
          </div>
        </section>

        {/* Security */}
        <section className="mb-40">
          <div>
            <h2 className="text-4xl sm:text-4xl md:text-[4rem] font-black mb-8">security</h2>
            <div className="space-y-8 text-3xl md:text-4xl text-white">
              <p>code is public</p>
              <p>contracts are verified</p>
              <p>no hidden mechanics</p>
              <p className="text-emerald-400 font-bold">maximum transparency.</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default BuyPage; 