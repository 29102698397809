import { motion } from 'framer-motion';
import { navigateExternal } from '../utils/navigation';

const SocialsPage = () => {
  return (
    <div className="min-h-screen bg-black text-white pt-40">
      <div className="max-w-7xl mx-auto px-4">
        {/* Hero Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="mb-40"
        >
          <h1 className="text-5xl sm:text-6xl md:text-[7rem] font-black mb-8">socials</h1>
          <p className="text-3xl md:text-4xl text-white">
            join the <span className="text-emerald-400 font-bold">revolution</span>
          </p>
        </motion.div>

        {/* Links Grid */}
        <section className="mb-40">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-32">
            <div>
              <div className="relative mb-16">
                <p className="text-5xl sm:text-6xl md:text-[8rem] font-black text-white/20 absolute -top-8 md:-top-16">01</p>
                <h3 className="text-3xl sm:text-4xl md:text-[4rem] font-black relative z-10">twitter</h3>
              </div>
              <div className="space-y-8 text-3xl md:text-4xl text-white">
                <p>announcements</p>
                <p>community updates</p>
                <button 
                  onClick={() => navigateExternal('https://twitter.com/degsai')}
                  className="text-emerald-400 font-bold hover:text-emerald-300 transition-colors"
                >
                  follow us
                </button>
              </div>
            </div>

            <div>
              <div className="relative mb-16">
                <p className="text-5xl sm:text-6xl md:text-[8rem] font-black text-white/20 absolute -top-8 md:-top-16">02</p>
                <h3 className="text-3xl sm:text-4xl md:text-[4rem] font-black relative z-10">discord</h3>
              </div>
              <div className="space-y-8 text-3xl md:text-4xl text-white">
                <p>meet the community</p>
                <p>share your ideas</p>
                <button 
                  onClick={() => navigateExternal('https://discord.gg/degs')}
                  className="text-emerald-400 font-bold hover:text-emerald-300 transition-colors"
                >
                  join us
                </button>
              </div>
            </div>

            <div>
              <div className="relative mb-16">
                <p className="text-5xl sm:text-6xl md:text-[8rem] font-black text-white/20 absolute -top-8 md:-top-16">03</p>
                <h3 className="text-3xl sm:text-4xl md:text-[4rem] font-black relative z-10">medium</h3>
              </div>
              <div className="space-y-8 text-3xl md:text-4xl text-white">
                <p>deep dives</p>
                <p>technical insights</p>
                <button 
                  onClick={() => navigateExternal('https://medium.com/@degsai')}
                  className="text-emerald-400 font-bold hover:text-emerald-300 transition-colors"
                >
                  read more
                </button>
              </div>
            </div>

            <div>
              <div className="relative mb-16">
                <p className="text-5xl sm:text-6xl md:text-[8rem] font-black text-white/20 absolute -top-8 md:-top-16">04</p>
                <h3 className="text-3xl sm:text-4xl md:text-[4rem] font-black relative z-10">github</h3>
              </div>
              <div className="space-y-8 text-3xl md:text-4xl text-white">
                <p>open source</p>
                <p>contribute</p>
                <button 
                  onClick={() => navigateExternal('https://github.com/degs-ai')}
                  className="text-emerald-400 font-bold hover:text-emerald-300 transition-colors"
                >
                  explore code
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default SocialsPage; 