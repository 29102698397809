import { motion } from 'framer-motion';
import { Tweet } from 'react-tweet';

interface ManifestoContentProps {
  activeSection: string;
  activeSubSection: string;
}

const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 }
};

const staggerChildren = {
  animate: {
    transition: {
      staggerChildren: 0.2
    }
  }
};

const ManifestoContent = ({ activeSection, activeSubSection }: ManifestoContentProps) => {
  return (
    <div className="flex-1 md:ml-64 p-8 pt-24 bg-gradient-to-b from-black via-black to-black/95">
      <motion.div 
        className="max-w-3xl mx-auto"
        initial="initial"
        animate="animate"
        variants={staggerChildren}
      >
        {/* Manifesto Section */}
        {activeSection === 'manifesto' && (
          <>
            <motion.h1 
              className="text-4xl md:text-7xl font-black mb-16 bg-gradient-to-r from-white to-white/80 bg-clip-text text-transparent"
              variants={fadeInUp}
            >
              Manifesto
            </motion.h1>
            
            <section id="the-problem-gaming-is-broken" className="mb-24">
              <motion.h2 
                className="text-4xl font-bold mb-8 text-white/90"
                variants={fadeInUp}
              >
                gaming is broken
              </motion.h2>
              <motion.div 
                className="space-y-8 text-lg"
                variants={staggerChildren}
              >
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  the gaming industry has become a <span className="text-emerald-400 font-bold">$300B+ dystopia.</span> a handful of corporations dictate what gets created, what stories get told, and who gets to tell them. they've turned gaming into a soulless machine of profit extraction.
                </motion.p>
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  remember when games were about <span className="text-emerald-400 font-bold">creativity?</span> when developers took risks? when microtransactions didn't infest every pixel of your screen? <span className="text-emerald-400 font-bold">we do.</span>
                </motion.p>
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  today, we're force-fed an endless stream of sequels, remakes, and "live services" designed not to entertain, but to extract maximum value from players. creativity isn't just dying - it's being actively suppressed.
                </motion.p>
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  the gatekeepers claim they're "protecting quality." <span className="text-red-400 font-semibold">bullshit</span>. they're protecting their monopoly on creation. they're terrified of what happens when everyone can create.
                </motion.p>
              </motion.div>
            </section>

            <section id="the-fix-degs" className="mb-24">
              <motion.h2 
                className="text-4xl font-bold mb-8 text-white/90"
                variants={fadeInUp}
              >
                the fix: degs
              </motion.h2>
              <motion.div 
                className="space-y-8 text-lg"
                variants={staggerChildren}
              >
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  we're not just building another game studio. we're building the <span className="text-emerald-400 font-bold">last game studio</span> you'll ever need. a decentralized platform where AI meets creativity, where anyone can turn their vision into reality without writing a single line of code.
                </motion.p>
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  imagine speaking your game idea into existence. no coding. no publishers. no bullshit. just pure creativity flowing from your mind to the screen. that's not science fiction - <span className="text-white font-semibold">that's what we've built</span>.
                </motion.p>
                <motion.div 
                  className="bg-white/5 p-8 rounded-lg border border-white/10"
                  variants={fadeInUp}
                >
                  <p className="text-white/80 leading-relaxed">
                    we're revolutionizing how creators get paid:
                    <span className="block mt-4 text-2xl font-semibold text-emerald-400">70% to creators</span>
                    <span className="block mt-2 text-xl text-emerald-400/80">30% to token holders</span>
                    <span className="block mt-4">transparent. immutable. fair.</span>
                    <span className="block mt-2 text-white/60">no more predatory publisher deals. no more revenue black boxes.</span>
                  </p>
                </motion.div>
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  this isn't just about making games. it's about breaking the chains that have held back creativity for decades. it's about giving power back to creators and players. it's about making gaming what it was always meant to be: <span className="text-emerald-400 font-bold">a universe of infinite possibilities</span>.
                </motion.p>
              </motion.div>
            </section>
          </>
        )}

        {/* Why Now Section */}
        {activeSection === 'why-now' && (
          <>
            <motion.h1 
              className="text-4xl md:text-7xl font-black mb-16 bg-gradient-to-r from-white to-white/80 bg-clip-text text-transparent"
              variants={fadeInUp}
            >
              Why Now
            </motion.h1>
            
            <motion.div 
              className="mb-16 w-full"
              variants={fadeInUp}
            >
              <div className="flex justify-center">
                <Tweet id="1861801046949191686" />
              </div>
            </motion.div>

            <motion.div 
              className="space-y-8 text-lg"
              variants={staggerChildren}
            >
              <motion.p 
                className="text-white/80 leading-relaxed"
                variants={fadeInUp}
              >
                we're standing at the precipice of a <span className="text-emerald-400 font-bold">revolution</span>. in mere months, AI will surpass human coding capabilities. the walls between imagination and creation are about to crumble. and when they do, an explosion of creativity will follow.
              </motion.p>
              <motion.p 
                className="text-white/80 leading-relaxed"
                variants={fadeInUp}
              >
                the old guards see this coming. they're terrified. <span className="text-white font-semibold">they should be</span>. when anyone can create a game by simply describing it, their monopoly on creation dies. their armies of developers become obsolete. their control over gaming ends.
              </motion.p>
              <motion.p 
                className="text-white/80 leading-relaxed"
                variants={fadeInUp}
              >
                we're starting with casual mobile games because that's what current AI can handle. but make no mistake - this is just the beginning. as AI evolves, so will the complexity of games that can be created. from simple puzzles to open-world epics, all through the power of your voice.
              </motion.p>
              <motion.div 
                className="bg-gradient-to-r from-blue-500/20 to-purple-500/20 p-8 rounded-lg border border-white/10"
                variants={fadeInUp}
              >
                <p className="text-2xl font-semibold text-white mb-4">the next gaming revolution won't come from a big studio</p>
                <p className="text-white/80">it will come from millions of creators, finally unleashed. and they'll need a platform. a home. a place where creativity flows freely and rewards flow fairly.</p>
              </motion.div>
              <motion.p 
                className="text-white/80 leading-relaxed"
                variants={fadeInUp}
              >
                that's what we're building. that's why we're building it now. because the future of gaming isn't about better graphics or bigger budgets. <span className="text-emerald-400 font-bold">it's about democratizing creation itself</span>.
              </motion.p>
            </motion.div>
          </>
        )}

        {/* Ecosystem Section */}
        {activeSection === 'ecosystem' && (
          <>
            <motion.h1 
              className="text-4xl md:text-7xl font-black mb-16 bg-gradient-to-r from-white to-white/80 bg-clip-text text-transparent"
              variants={fadeInUp}
            >
              Ecosystem
            </motion.h1>
            
            <section id="platform-overview" className="mb-24">
              <motion.h2 
                className="text-4xl font-bold mb-8 text-white/90"
                variants={fadeInUp}
              >
                overview
              </motion.h2>
              <motion.div 
                className="space-y-8 text-lg"
                variants={staggerChildren}
              >
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  degs isn't just a platform - it's an <span className="text-emerald-400 font-bold">ecosystem designed for the AI-powered future</span> of game creation. at its core is a revolutionary AI engine that turns spoken words into playable games. no coding required. no technical knowledge needed. just pure creativity.
                </motion.p>
                <motion.div 
                  className="bg-gradient-to-r from-blue-500/20 to-purple-500/20 p-8 rounded-lg border border-white/10"
                  variants={fadeInUp}
                >
                  <p className="text-2xl font-semibold text-white mb-4">single file. instant deployment.</p>
                  <p className="text-white/80">every game is a single file. HTML5. React. whatever works best. deployed instantly to our decentralized network. no app stores. no approval processes. no waiting. create, publish, and start earning in minutes.</p>
                </motion.div>
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  our monetization system is built on <span className="text-emerald-400 font-bold">blockchain technology</span>. transparent. immutable. fair. decentralized ads run on smart contracts, ensuring maximum revenue for creators. in-app purchases flow directly through the ecosystem, with creators getting the lion's share.
                </motion.p>
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  MetaMask integration ensures <span className="text-emerald-400 font-bold">secure, instant payments</span>. no paperwork. no delays. no bullshit. create something amazing, and you'll be rewarded immediately. that's how it should have always been.
                </motion.p>
              </motion.div>
            </section>

            <section id="game-creation" className="mb-24">
              <motion.h2 
                className="text-4xl font-bold mb-8 text-white/90"
                variants={fadeInUp}
              >
                game creation
              </motion.h2>
              <motion.div 
                className="space-y-8 text-lg"
                variants={staggerChildren}
              >
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  creating a game on degs is as natural as having a conversation. describe your vision - the gameplay, the aesthetics, the feel. our AI understands context, nuance, and intent. it doesn't just follow instructions - it <span className="text-emerald-400 font-bold">collaborates with you</span>.
                </motion.p>
                <motion.div 
                  className="bg-white/5 p-8 rounded-lg border border-white/10"
                  variants={fadeInUp}
                >
                  <p className="text-white/80 leading-relaxed">
                    the process is simple:
                    <span className="block mt-4 text-xl text-emerald-400">1. describe your vision</span>
                    <span className="block mt-2 text-xl text-emerald-400/80">2. AI generates your game</span>
                    <span className="block mt-2 text-xl text-emerald-400/80">3. refine through conversation</span>
                    <span className="block mt-2 text-xl text-emerald-400/80">4. publish and monetize</span>
                  </p>
                </motion.div>
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  iteration is <span className="text-emerald-400 font-bold">instant</span>. don't like something? say it. want to change the mechanics? describe it. the AI adapts in real-time, letting you perfect your vision without technical constraints. this is game development at the speed of thought.
                </motion.p>
              </motion.div>
            </section>
          </>
        )}

        {/* $DEGS Section */}
        {activeSection === 'degs-token' && (
          <>
            <motion.h1 
              className="text-4xl md:text-7xl font-black mb-16 bg-gradient-to-r from-white to-white/80 bg-clip-text text-transparent"
              variants={fadeInUp}
            >
              $DEGS Token
            </motion.h1>
            
            <section id="overview" className="mb-24">
              <motion.h2 
                className="text-4xl font-bold mb-8 text-white/90"
                variants={fadeInUp}
              >
                overview
              </motion.h2>
              <motion.div 
                className="space-y-8 text-lg"
                variants={staggerChildren}
              >
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  $DEGS isn't just another token. it's the <span className="text-emerald-400 font-bold">lifeblood of our ecosystem</span>. built on Base, it powers every transaction, every reward, every interaction within our platform. it's designed for one purpose: to align the interests of creators, players, and holders.
                </motion.p>
                <motion.div 
                  className="bg-gradient-to-r from-blue-500/20 to-purple-500/20 p-8 rounded-lg border border-white/10"
                  variants={fadeInUp}
                >
                  <p className="text-2xl font-semibold text-white mb-4">closed loop of value</p>
                  <p className="text-white/80">when games generate revenue, it flows through $DEGS. when creators earn, they earn in $DEGS. when holders stake, they receive their share of platform revenue in $DEGS.</p>
                </motion.div>
              </motion.div>
            </section>

            <section id="tokenomics" className="mb-24">
              <motion.h2 
                className="text-4xl font-bold mb-8 text-white/90"
                variants={fadeInUp}
              >
                tokenomics
              </motion.h2>
              <motion.div 
                className="space-y-8 text-lg"
                variants={staggerChildren}
              >
                <motion.div 
                  className="bg-white/5 p-8 rounded-lg border border-white/10"
                  variants={fadeInUp}
                >
                  <p className="text-2xl font-semibold text-emerald-400 mb-4">1B $DEGS</p>
                  <div className="space-y-4">
                    <p className="text-white/80">
                      <span className="text-emerald-400">55%</span> powers liquidity
                      <span className="block ml-4 text-sm">- 10% initial DEX offering, locked for 24 months</span>
                      <span className="block ml-4 text-sm">- 45% future CEX/DEX, logarithmic release over 12 months</span>
                    </p>
                    <p className="text-white/80">
                      <span className="text-emerald-400">15%</span> fuels the community
                      <span className="block ml-4 text-sm">logarithmic distribution over 24 months</span>
                    </p>
                    <p className="text-white/80">
                      <span className="text-emerald-400">10%</span> team allocation
                      <span className="block ml-4 text-sm">6-month cliff, 2-year linear vesting</span>
                    </p>
                    <p className="text-white/80">
                      <span className="text-emerald-400">10%</span> marketing
                      <span className="block ml-4 text-sm">logarithmic release over 12 months</span>
                    </p>
                    <p className="text-white/80">
                      <span className="text-emerald-400">5%</span> private sale
                      <span className="block ml-4 text-sm">no restrictions</span>
                    </p>
                    <p className="text-white/80">
                      <span className="text-emerald-400">5%</span> airdrop + contests
                      <span className="block ml-4 text-sm">logarithmic release over 12 months</span>
                    </p>
                  </div>
                </motion.div>
              </motion.div>
            </section>

            <section id="dyor" className="mb-24">
              <motion.h2 
                className="text-4xl font-bold mb-8 text-white/90"
                variants={fadeInUp}
              >
                dyor
              </motion.h2>
              <motion.div 
                className="space-y-8 text-lg"
                variants={staggerChildren}
              >
                <motion.p 
                  className="text-white/80 leading-relaxed"
                  variants={fadeInUp}
                >
                  contract address: <span className="text-emerald-400">[coming soon]</span>. every line of code will be public. every vesting contract will be verifiable. every transaction will be traceable.
                </motion.p>
                <motion.div 
                  className="bg-gradient-to-r from-blue-500/20 to-purple-500/20 p-8 rounded-lg border border-white/10"
                  variants={fadeInUp}
                >
                  <p className="text-2xl font-semibold text-white mb-4">verify everything</p>
                  <p className="text-white/80">we're building this in the open. no hidden mechanics. no backdoors. no "admin keys." the code is the law, and the law is fair.</p>
                </motion.div>
              </motion.div>
            </section>
          </>
        )}

        {/* Path Section */}
        {activeSection === 'path' && (
          <>
            <motion.h1 
              className="text-4xl md:text-7xl font-black mb-16 bg-gradient-to-r from-white to-white/80 bg-clip-text text-transparent"
              variants={fadeInUp}
            >
              Path
            </motion.h1>
            <motion.div 
              className="space-y-8 text-lg"
              variants={staggerChildren}
            >
              <motion.div 
                className="bg-white/5 p-8 rounded-lg border border-white/10"
                variants={fadeInUp}
              >
                <p className="text-2xl font-semibold text-emerald-400 mb-6">phase 1: now</p>
                <p className="text-white/80 mb-8">casual mobile games. simple mechanics. instant creation. proving that anyone can be a game developer. showing the world what's possible when AI meets creativity.</p>
                
                <p className="text-2xl font-semibold text-emerald-400 mb-6">phase 2: evolution</p>
                <p className="text-white/80 mb-8">as AI capabilities grow, so do the games. more complex mechanics. deeper systems. richer experiences. still created through natural conversation, but with the depth gamers crave.</p>
                
                <p className="text-2xl font-semibold text-emerald-400 mb-6">phase 3: revolution</p>
                <p className="text-white/80">AAA-quality games created through AI. open worlds. complex narratives. cutting-edge graphics. the full power of modern gaming, accessible to anyone with an idea.</p>
              </motion.div>
              
              <motion.p 
                className="text-white/80 leading-relaxed"
                variants={fadeInUp}
              >
                this isn't a roadmap. it's an <span className="text-emerald-400 font-bold">inevitability</span>. as AI evolves, the barriers between imagination and creation will continue to fall. we're not just riding this wave - we're creating it.
              </motion.p>
              
              <motion.div 
                className="bg-gradient-to-r from-blue-500/20 to-purple-500/20 p-8 rounded-lg border border-white/10"
                variants={fadeInUp}
              >
                <p className="text-2xl font-semibold text-white mb-4">the future is now</p>
                <p className="text-white/80">the future of gaming isn't more polygons or bigger marketing budgets. it's pure creativity, unleashed. it's millions of voices, finally heard. it's games that could never exist in the old world, created by people who were never given a chance.</p>
              </motion.div>
            </motion.div>
          </>
        )}

        {/* Closing Thoughts Section */}
        {activeSection === 'closing-thoughts' && (
          <>
            <motion.h1 
              className="text-4xl md:text-7xl font-black mb-16 bg-gradient-to-r from-white to-white/80 bg-clip-text text-transparent"
              variants={fadeInUp}
            >
              per aspera ad astra
            </motion.h1>
            <motion.div 
              className="space-y-8 text-lg"
              variants={staggerChildren}
            >
              <motion.p 
                className="text-white/80 leading-relaxed"
                variants={fadeInUp}
              >
                through hardships to the stars. the old guard won't give up their power easily. the critics will say it can't be done. the skeptics will doubt every step.
              </motion.p>
              <motion.div 
                className="bg-gradient-to-r from-blue-500/20 to-purple-500/20 p-8 rounded-lg border border-white/10"
                variants={fadeInUp}
              >
                <p className="text-2xl font-semibold text-white mb-4">let them doubt</p>
                <p className="text-white/80">let them resist. let them cling to their dying model of controlled creativity and extracted value. we're not building for them.</p>
              </motion.div>
              <motion.p 
                className="text-white/80 leading-relaxed"
                variants={fadeInUp}
              >
                we're building for the <span className="text-emerald-400 font-bold">dreamers</span>. the creators. the ones who see gaming not as it is, but as it could be. we're building for everyone who's ever had an idea for a game but lacked the tools to make it real.
              </motion.p>
              <motion.p 
                className="text-white/80 leading-relaxed"
                variants={fadeInUp}
              >
                this isn't just about making games. this is about making <span className="text-emerald-400 font-bold">history</span>. about fundamentally changing how creativity flows in the digital age. about giving power back to the people who truly deserve it.
              </motion.p>
              <motion.div 
                className="bg-white/5 p-8 rounded-lg border border-white/10"
                variants={fadeInUp}
              >
                <p className="text-2xl font-semibold text-emerald-400">the stars are waiting</p>
                <p className="text-white/80 mt-4">and we're just getting started.</p>
              </motion.div>
            </motion.div>
          </>
        )}
      </motion.div>
    </div>
  );
};

export default ManifestoContent; 