import { useState, useEffect } from 'react';
import ManifestoSidebar from '../components/manifesto/ManifestoSidebar';
import ManifestoContent from '../components/manifesto/ManifestoContent';

// Map of default subsections for each section
const defaultSubsections: { [key: string]: string } = {
  'manifesto': 'the-problem-gaming-is-broken',
  'ecosystem': 'platform-overview',
  'degs-token': 'overview'
};

const ManifestoPage = () => {
  const [activeSection, setActiveSection] = useState('manifesto');
  const [activeSubSection, setActiveSubSection] = useState('the-problem-gaming-is-broken');
  const [shouldScroll, setShouldScroll] = useState(false);

  const handleSectionChange = (section: string) => {
    setActiveSection(section);
    // Reset scroll position when changing section
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    // If the section has a default subsection, select it without scrolling
    if (defaultSubsections[section]) {
      setShouldScroll(false); // Prevent scroll on section change
      setActiveSubSection(defaultSubsections[section]);
    }
  };

  const handleSubSectionChange = (subsection: string) => {
    setShouldScroll(true); // Enable scroll for subsection clicks
    setActiveSubSection(subsection);
  };

  useEffect(() => {
    // Scroll to subsection only if shouldScroll is true
    if (activeSubSection && shouldScroll) {
      const element = document.getElementById(activeSubSection);
      if (element) {
        const navbarHeight = 64; // Height of the navbar in pixels
        const mobileOffset = window.innerWidth < 768 ? 112 : 0; // Additional offset for mobile menu
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - navbarHeight - mobileOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  }, [activeSubSection, shouldScroll]);

  return (
    <div className="pt-16 md:pt-16 min-h-screen bg-black">
      <div className="flex">
        <ManifestoSidebar 
          activeSection={activeSection}
          activeSubSection={activeSubSection}
          onSectionChange={handleSectionChange}
          onSubSectionChange={handleSubSectionChange}
        />
        <ManifestoContent 
          activeSection={activeSection}
          activeSubSection={activeSubSection}
        />
      </div>
    </div>
  );
};

export default ManifestoPage; 