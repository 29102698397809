import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import PathPage from './pages/PathPage';
import EcosystemPage from './pages/EcosystemPage';
import BuyPage from './pages/BuyPage';
import SocialsPage from './pages/SocialsPage';
import TokenPage from './pages/TokenPage';
import ManifestoPage from './pages/ManifestoPage';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="min-h-screen bg-black">
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/path" element={<PathPage />} />
          <Route path="/ecosystem" element={<EcosystemPage />} />
          <Route path="/token" element={<TokenPage />} />
          <Route path="/buy" element={<BuyPage />} />
          <Route path="/socials" element={<SocialsPage />} />
          <Route path="/manifesto" element={<ManifestoPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
