import { useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';

const Navbar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <>
      <nav className="fixed w-full z-50 top-0 left-0 px-6 py-4 bg-black/80 backdrop-blur-sm border-b border-white/10">
        <div className="max-w-7xl mx-auto flex items-center justify-between">
          <Link to="/" className="text-2xl font-bold text-white">
            degs
          </Link>
          
          {/* Mobile Menu Button */}
          <button 
            onClick={() => setIsSidebarOpen(true)}
            className="md:hidden text-white"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <Link to="/ecosystem" className="text-white/80 hover:text-white transition-colors">
              ecosystem
            </Link>
            <Link to="/path" className="text-white/80 hover:text-white transition-colors">
              path
            </Link>
            <Link to="/token" className="text-white/80 hover:text-white transition-colors">
              $degs
            </Link>
            <Link to="/buy" className="text-white/80 hover:text-white transition-colors">
              buy
            </Link>
            <Link to="/socials" className="text-white/80 hover:text-white transition-colors">
              socials
            </Link>
            <Link to="/manifesto" className="px-4 py-2 bg-white text-black font-medium rounded-lg hover:bg-white/90 transition-colors">
              manifesto
            </Link>
            <a
              href="https://app.degs.ai"
              target="_blank"
              rel="noopener noreferrer"
              className="px-4 py-2 bg-white text-black font-medium rounded-lg hover:bg-white/90 transition-colors"
            >
              launch app
            </a>
          </div>
        </div>
      </nav>

      {/* Mobile Sidebar */}
      <Sidebar 
        isOpen={isSidebarOpen} 
        onClose={() => setIsSidebarOpen(false)} 
      />
    </>
  );
};

export default Navbar; 